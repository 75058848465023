import React from "react";
import { Script } from "gatsby";
const Reviews = () => {
  return (
    <div
      style={{
        padding: "20px 0",
      }}
    >
      <Script src="https://apps.elfsight.com/p/platform.js" async></Script>
      <div class="elfsight-app-2763d7b9-104e-436a-8b51-b34ab334dc34"></div>
    </div>
  );
};

export default Reviews;
