import React, { useState } from "react";
import { SEO } from "../components";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import FooterHeadline from "../components/layout/footerheadline";
import AboutUsMain from "../components/aboutus/about-us-main";
import RealEstateSection from "../components/layout/real-estate-section";
import MailSection from "../components/aboutus/about-us-mail-section";
import Form from "../components/aboutus/about-us-from";
import classNames from "classnames";
import { Container } from "reactstrap";
import SideBar from "../components/layout/sidebar/sidebar";
import Topbar from "../components/layout/content/topbar";
import TeamMain from "../components/team/team-main";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { graphql } from "gatsby";
import Modal from "../components/aboutus/modal";
import Reviews from "../components/reviews.jsx/index.jsx";

const Testimonials = ({ data }) => {
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const { ref, inView, entry } = useInView();

  const handleBrightness = () => {
    if (sidebarIsOpen) {
      return "brightness(50%)";
    } else {
      return "brightness(100%)";
    }
  };
  return (
    <div
      style={{
        maxWidth: "100vw",
        width: "100%",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <SEO title="Testimonials" description="" keywords={[]} ldSchema={[]} />
      <div className="App wrapper" id="top">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Container
          fluid
          className={classNames("p-0 m-0", { "is-open": sidebarIsOpen })}
          style={{
            filter: `${handleBrightness()}`,
          }}
        >
          <Header>
            {!sidebarIsOpen ? <Topbar toggleSidebar={toggleSidebar} /> : null}
          </Header>
          <div className="margin-class"></div>
          <AboutUsMain
            fontSize="35px"
            title="Testimonials"
            bread="ZILLOW & GOOGLE REVIEWS"
            buttontext="PHRE MARKETING"
            onClick={toggle}
            src="https://cdn.sanity.io/images/36ueq3h7/production/a137e88bb32eee5d2b275959e676090211968ee8-1400x933.jpg"
            cover="cover"
            position="50% 60%"
          />
          {!!modal && (
            <Modal
              modal={modal}
              toggle={toggle}
              videourl="https://cdn.sanity.io/files/36ueq3h7/production/21a4bde22528995a154fbd6e8459e38d71aea4cc.mp4"
            />
          )}
          <div
            style={{
              backgroundColor: "#f6f6f6",
            }}
          >
            <Reviews />
            {/* <TeamMain team={sanityTeam} /> */}
          </div>
          <div ref={ref}>
            <Form />
            <MailSection />

            <Footer />
            <div
              style={{
                borderTop: "1px solid green",
              }}
            >
              <FooterHeadline />
            </div>
          </div>
        </Container>
      </div>
      {inView && (
        <div>
          <p
            className="back-to-top in scroll-up-button"
            onClick={() => {
              scrollTo("#top");
            }}
          >
            <FontAwesomeIcon
              className="scroll-up-button-icon mt-2"
              icon={faAngleUp}
            />
          </p>
        </div>
      )}
    </div>
  );
};
export default Testimonials;
